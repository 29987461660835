import React, { useState } from "react";
import { getDescendants, NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import styles from "./styles.module.css";
import { FormSubmit, InputSingleImage, KTFormItem, KTSVG, RowIcon } from "../../../../theme/partials";
import { Controller } from "react-hook-form";
import { removeAccents } from "../../../../theme/helpers";

const TREE_X_OFFSET = 22;
const typeLink = 'CustomLinks'

const Node: React.FC<{
  node: NodeModel;
  depth: number;
  isOpen: boolean;
  isDropTarget: boolean;
  treeData: NodeModel[];
  onClick: (id: NodeModel["id"]) => void;
  handleDelete: (id: NodeModel["id"]) => void;
  getPipeHeight: (id: string | number, treeData: NodeModel[]) => number;
  onTextChange: (id: NodeModel["id"], value: string, props?: any) => void;
  isPermission?: any;
  control: any,

}> = ({
  node,
  depth,
  isOpen,
  isDropTarget,
  onClick,
  treeData,
  getPipeHeight,
  handleDelete,
  onTextChange,
  isPermission,
  control,
}) => {
  const indent = depth * TREE_X_OFFSET;
  const { id, text, name, menuitemtype, url, highlightfirstitem, image, slug, menuobjectcode } = node as any;

  const [inputimage, setInputimage] = useState(image);
  const [inputslug, setInputslug] = useState(slug?slug:removeAccents(text));
  const [isShowSlug, setIsShowSlug] = useState(false);
  
  const [visibleInput, setVisibleInput] = useState(false);
  const [labelText, setLabelText] = useState(text);
  const [customlink, setCustomlink] = useState(url);
  const [itemHighlight, setItemHighlight] = useState(highlightfirstitem);
  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(id);
  };
  const handleShowInput = () => {
    setVisibleInput(true);
  };

  const handleCancel = () => {
    setLabelText(text);
    setItemHighlight(highlightfirstitem)
    if(menuitemtype == typeLink) {
      setCustomlink(url)
    }
    setVisibleInput(false);
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelText(e.target.value);
    setInputslug(removeAccents(e.target.value))
  };
  const handleChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomlink(e.target.value);
  };
  const handleSubmit = () => {
    setVisibleInput(false);
    onTextChange(id, labelText, {url: customlink, highlightfirstitem: itemHighlight, image: inputimage, inputslug: inputslug});
  };
  const dragOverProps = useDragOver(id, isOpen, onClick);
  function checkMenuParent(id: string){
    return treeData.filter(f => f.parent == id).length > 0
  }
  return (
    <div
      className={`tree-node ${
        node.droppable && isDropTarget ? styles.dropTarget : ""
      }`}
      style={{ marginInlineStart: indent }}
      {...dragOverProps}
      
    >
      
        
        {/* <div className="wrapitem"> */}
          {/* <NodeIcon type={node.droppable ? (isOpen ? "folder-open" : "folder") : null} /> */}
          <div  className={styles.pipeX}  style={{ width: depth > 0 ? TREE_X_OFFSET - 9 : 0 }} />
          {/* {getDescendants(treeData, node.parent)[0].id === id && (
            <div
              className={styles.pipeY}
              style={{
                height: Math.max(0, getPipeHeight(node.parent, treeData) - 8)
              }}
            />
          )} */}
          <div className="d-flex flex-stack my-2" >
            <div className='labelGridItem w-100'>
              <div className="border border-1 border-secondary bg-opacity-50 bg-secondary p-2 pe-1 d-flex flex-stack">
                <div className="w-100 d-flex flex-stack cursor-pointer" onClick={e=>{
                  !visibleInput ? handleShowInput() : handleCancel()
                }}>
                  <span>{menuobjectcode ? `${menuobjectcode} - ` : ''}{text}</span>
                  <span className="text-gray-600">{menuitemtype}</span>
                </div>
                <div className="d-flex gap-2">
                  {/* <RowIcon action="modify" onClick={handleShowInput} /> */}
                  
                  {node.droppable && 
                    <div className={`ps-1 d-flex flex-center ${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ""}`} >
                      <span className={`btn btn-sm btn-icon btn-link w-20px h-20px ${ checkMenuParent(id) ? 'visible' : 'invisible' }`}
                        onClick={handleToggle}>
                        <i className="bi bi-caret-right-fill"></i>
                    </span>
                  </div>
                  }
                  
                </div>
              </div>
              {visibleInput && <div className='border border-1 border-secondary p-3'>
                {menuitemtype != typeLink && <>
                  <KTFormItem title='Navigation Label' isBLockLabel>
                    <input
                      type="text"
                      className={`form-control form-control-sm`}
                      value={labelText}
                      onChange={handleChangeText}
                    />
                  </KTFormItem>
                  {!isShowSlug && 
                    <div className="cursor-pointer d-flex gap-3 mb-4 ps-4"  onClick={()=> {
                      setIsShowSlug(!isShowSlug)
                    }}>
                      <span className="text-gray-600">{inputslug}</span>
                      <span ><i className="bi bi-pencil-fill text-primary"></i></span>
                    </div>
                  }
                  {
                    isShowSlug &&
                    <KTFormItem title='Slug' isBLockLabel>
                      <input
                        type="text"
                        className={`form-control form-control-sm`}
                        value={inputslug}
                        onChange={e => {
                          setInputslug(e.target?.value)
                        }}
                      />
                    </KTFormItem>
                  }
                  
                </>
                }
                {menuitemtype == typeLink && <div className="d-flex gap-4">
                  <KTFormItem title='Images' isHeight isBLockLabel>
                    <Controller
                        name="image"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSingleImage
                            size="1.25:1.25"
                            value={inputimage}
                            onChange={e=> {
                              setInputimage(e)
                              onChange(e)
                            }} />}
                    />
                </KTFormItem>
                <div className="w-100">
                  <KTFormItem title='Navigation Label' isBLockLabel>
                    <input
                      type="text"
                      className={`form-control form-control-sm`}
                      value={labelText}
                      onChange={handleChangeText}
                    />
                  </KTFormItem>
                  <KTFormItem title='Custom Link' isBLockLabel>
                    <input
                      type="text"
                      className={`form-control form-control-sm`}
                      value={customlink}
                      onChange={handleChangeLink}
                    />
                  </KTFormItem>
                </div>
                
                </div>
                }
                <label className="form-check form-check-sm form-check-solid mb-3">
                  <input className=" form-check-input me-2" type="checkbox" checked={itemHighlight} onChange={e=>{
                    setItemHighlight(e.target.checked)
                  }} />
                  <span className="cursor-pointer">Highlight first item</span>
                </label>
              <div className="d-flex flex-stack">
                  {isPermission && 
                    <RowIcon action="delete" notQuestion={true} onClick={async () => {
                      handleDelete(id)
                    }} />
                  }
                  <div className="d-flex gap-3 align-items-center">
                    <span className="text-gray-600">{name}</span>
                    
                  </div>
                  {isPermission && 
                    <button
                      onClick={e=>{
                        handleSubmit()
                      }} className="btn btn-primary btn-sm">
                      <span>
                        <i className="bi bi-check-lg fs-5"></i>
                        Save
                      </span>
                  </button>
                  }
                  
              </div>
                
              </div> }
            </div>
            
          </div>
        {/* </div> */}
    </div>
  );
};

export default Node;
