import clsx from "clsx";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { ApiService, ApiShowError, debounce } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { InputSelect } from "../../../../theme/partials";
import { getIsPermission } from "../../../modules/auth/Permission";
import { MergedProps } from "../../../router/AppRoutes";
import { optionVatBusPostingGroups } from "../../fnb/merchants/detail";
import { useQuery } from "react-query";

interface ICustomerPriceGroup {
    id: string
    code: string
    description: string
    vatbuspostinggroup: string
    ispriceincltax: boolean
}

interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
    id?: string
    value: any
}

const ENTER_KEY = 13
const TAB_KEY = 9

const urlQuery = `fnb/customerpricegroups`
export default function CustomerPriceGroups({ keyElement, permission }: MergedProps) {
    const headers = [
        { width: 150, key: 'code', name: 'Code' },
        { width: undefined, key: 'description', name: 'Description' },
        { width: 175, key: 'vatbuspostinggroup', name: 'VAT Bus. Posting Group', type: 'select', options: optionVatBusPostingGroups },
        { width: 100, key: 'ispriceincltax', name: 'Price Incl. Tax', type: 'truefalse' },
    ]
    const { data: customerPriceGroups, isFetching } = useQuery([urlQuery], () => ApiService.get(urlQuery).then(res => res.data))
    const [dataSource, setDataSource] = useState<GridElement[][]>([])

    useEffect(() => {
        if (customerPriceGroups && !isFetching) {
            const array: ICustomerPriceGroup[] = customerPriceGroups
            setDataSource(array.map((item) => generateLine(item)))
        }
    }, [isFetching])

    async function deleteRow(indexToRemove: number) {
        const temp = dataSource[indexToRemove].at(0)
        if (temp?.id) {
            await ApiService.delete(`${urlQuery}/${temp?.id}`)
        }
        setDataSource((prevState) =>
            prevState.filter((row, index) => index !== indexToRemove)
        );
    }

    function generateLine(obj: any) {
        return headers.map(header => ({ value: obj[header.key], id: obj?.id }))
    }

    async function onCellsChanged(changes: any[], additions?: any[]) {
        const grid = dataSource.map((row) => [...row])
        const arraySaves: number[] = []
        // console.log(changes.concat(additions || []))
        changes.concat(additions || []).forEach(({ cell, row, col, value }) => {
            if (!grid[row] && value) {
                grid[row] = headers.map(col => ({ value: '' }))
            }
            if (grid[row] && grid[row][col]) {
                grid[row][col] = { ...grid[row][col], value }
            }
            if (!arraySaves.includes(row)) {
                arraySaves.push(row)
            }
        })
        // console.log(arraySaves)
        let bodyData = grid
            .filter((f, i) => arraySaves.includes(i))
            .map((item) => {
                let line: { [key: string]: any } = {}
                const lineid = item.at(0)?.id
                if (lineid) {
                    line['id'] = lineid
                }
                if (!lineid && !item[0].value) {
                    return {};
                }
                headers.forEach((header, col) => {
                    if (!item[col].value && !lineid) {
                        if (header.key == 'vatbuspostinggroup') {
                            line[header.key] = 'NO-VAT'
                        }
                        if (header.key == 'ispriceincltax') {
                            line[header.key] = true
                        }
                    } else {
                        if (header.type == 'truefalse') {
                            line[header.key] = item[col].value == true
                        } else {
                            line[header.key] = item[col].value
                        }
                    }
                })
                return line
            })
        bodyData = bodyData.filter(f => f && f.code)
        if (!bodyData.length) return;
        try {
            const results = await ApiService.post(`${urlQuery}/upsertmulti`, bodyData)
            const temps = results.data
            // createdItems
            // updatedItems
            const girdPrev = grid.filter(f => f.at(0)?.id)
            setDataSource([...girdPrev, ...(temps.createdItems || []).map((m: any) => generateLine(m))])
        } catch (error) {
            ApiShowError(error)
        }
    }

    const isUpdate = permission && getIsPermission([permission, 'UPDATE'])
    const isDelete = permission && getIsPermission([permission, 'DELETE'])

    function renderGrirdValues() {
        const newRow = generateLine({})
        return [...onFilter(), newRow].map((rows, rowIndex) => {

            const temps: any[] = []
            rows.forEach((col: any, colIndex: number) => {
                const header = headers[colIndex]
                if (!header) return;
                const newCol: GridElement = { ...col, ...header }
                if (header.type == 'select') {
                    let options = header.options || []
                    let temp: any = null
                    newCol['dataEditor'] = (props: any) => InputSelect({
                        className: 'data-editor rounded-0 text-start',
                        autoFocus: true,
                        openMenuOnFocus: true,
                        value: options.find((f: any) => f.value == props.value),
                        onKeyDown(event) {
                            // record last key pressed so we can handle enter
                            if (event.which === ENTER_KEY || event.which === TAB_KEY) {
                                event.persist()
                                temp = event
                            } else {
                                temp = null
                            }
                        },
                        onChange: (option) => {
                            const { onCommit, onRevert } = props
                            if (!option) {
                                return onRevert()
                            }
                            onCommit(option.value, temp)
                        },
                        border: false,
                        options: options,
                    })
                } else if (header.type == 'truefalse') {
                    if (rows.at(0)?.id) {
                        newCol['component'] = <span className="value-viewer">
                            <input
                                type="checkbox"
                                checked={newCol.value == true}
                                onChange={(e) => {
                                    onCellsChanged([{
                                        row: rowIndex,
                                        col: colIndex,
                                        cell: header,
                                        value: e.target.checked
                                    }])
                                }}
                            />
                        </span>
                        newCol['forceComponent'] = true
                    } else {
                        newCol['readOnly'] = true
                        newCol['disableEvents'] = true
                    }
                }
                temps.push({ ...newCol, readOnly: !isUpdate } as GridElement)
            })
            return [
                ...temps,
                {
                    width: 50,
                    disableEvents: true,
                    readOnly: true,
                    forceComponent: true,
                    component: temps.at(0).id
                        ? <button disabled={!isDelete} className="btn btn-light px-2 py-1 text-center bg-transparent" onClick={() => deleteRow(rowIndex)}>
                            <i className="bi bi-trash3 p-0"></i>
                        </button>
                        : null
                }
            ]
        })
    }

    const [text, setText] = useState("")
    const [filterValue, setFilterValue] = useState({ keyword: "" })

    function onFilter() {
        let tempsData = dataSource
        if (filterValue.keyword) {
            tempsData = dataSource.filter(cols => {
                return cols.some(col => {
                    return typeof col.value == 'string'
                        && col.value.toLowerCase().indexOf(filterValue.keyword.toLowerCase()) > -1
                })
            })
        }
        return tempsData
    }

    const onSearch = debounce(function (value: string) {
        setFilterValue({ keyword: value })
    }, 500)

    return <>
        <ContentHeader title={keyElement} />
        <Card body>
            <div className="d-flex align-items-center mb-6">
                <input
                    placeholder="Search"
                    className="form-control w-200px me-auto"
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value)
                        onSearch(e.target.value)
                    }}
                />
            </div>
            <div className="table-responsive">
                <ReactDataSheet
                    valueRenderer={(cell) => cell.value}
                    data={renderGrirdValues()}
                    sheetRenderer={(props: any) => {
                        return <table className={props.className + ' table table-bordered border-secondary m-0'}>
                            <thead>
                                <tr className="fw-bold text-gray-800">
                                    {headers.map((header, ind: number) => (
                                        <th key={ind} className={clsx('ps-1')} style={{ width: header.width ? `${header.width}px` : undefined }}>
                                            {header.name}
                                        </th>
                                    ))}
                                    <th style={{ width: `50px` }}></th>
                                </tr>
                            </thead>
                            <tbody>{props.children || null}</tbody>
                        </table>
                    }}
                    cellRenderer={({ cell, className, updated, attributesRenderer, editing, ...props }: any) => {
                        return <td {...props} className={clsx(className, 'ps-1', { 'text-center': !cell.key })}>
                            {props.children}
                        </td>
                    }}
                    onCellsChanged={onCellsChanged}
                />
            </div>
        </Card>
    </>
}