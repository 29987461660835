import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Column } from "react-table"
import { ApiService, sortByUpdateAt } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, KTButton, KTFormItem, KTTable, RowIcon } from "../../../../theme/partials"
import { IInitField, IInitFieldAddress } from "../../../modules/api"
import { optionAddress } from "../../../modules/api/address"
import { apiFnb } from "../../../modules/api/fnb"
import { popupMessage } from "../../../modules/messages"
import { MergedProps } from "../../../router/AppRoutes"

export interface IHospDiningAreaSection extends IInitField {
    seq: number
    sectioncode: string | null
    sectionname: string | null
    salestype: string | null
    servicetype: string
    merchantid: string | null
}

export interface IHospDiningTable extends IInitField {
    merchantid: string | null
    sectionid: string | null
    sectioncode: string | null
    tableno: string | null
    tablename: string | null
    seatcapacity: number | null
    mincapacity: number | null
    maxcapacity: number | null
    qrcodeordertype: string | null
    qrcodetype: string | null
    expirationtime: number | null
    qrexpireat: string | null
    qrcodethumb: string | null
    qrcode: string | null
    active: boolean
    diningtablestatus?: string | null
    salestype?: string | null
}

export interface IHospStaff extends IInitField {
    merchantid: string | null
    merchantkey: string | null
    code: string | null
    fullname: string | null
    nameonreceipt: string | null
    blocked: boolean
    datetobeblocked: string | null
    lastzreport: string | null
    loggedintopos: string | null

    staffpermissiongroupcode?: string | null
    staffpermissiongroupid?: string | null
    address2?: string | null
    homephoneno?: string | null
    workphoneno?: string | null
    password?: string | null
    ispasswordchange?: boolean
    payrollno?: string | null
    hourlyrate?: string | null
}

export interface ISMerchantPrinter extends IInitField {
    code: string | null
    name: string | null
    type: string | null
    host: string | null
    port: number | null
    serverhttps: string | null
    logosize: string | null
    logoalign: string | null
    qrcodewidth: number | null
    qrcodeheight: number | null
    active: boolean
    merchantid: string | null
}

export interface ISMerchant extends IInitFieldAddress {
    storecode: string | null
    storename: string | null
    merchantkey: string | null
    merchanturl: string | null
    merchanturlpublic: boolean
    address: string | null
    ward: string | null
    district: string | null
    zipcode: string | null
    province: string | null
    state: string | null
    country: string | null
    phoneno: string | null
    email: string | null
    latitude: string | null
    longitude: string | null
    countryphonecode: string | null
    timezonecode: string | null
    displaymode: string | null
    thousandseparator: string | null
    decimalseparator: string | null
    showitempicture: boolean
    autoacceptorder: boolean
    allowreceiptprinter: boolean
    allowkitchenprinter: boolean
    allowreprint: boolean
    notificationviazalo: boolean
    notificationviasms: boolean
    notificationviaemail: boolean
    smartwebsplashscreen: boolean
    guestmodelogin: boolean
    enableOTP: boolean
    storetiercode: string | null
    menustructuretiercode: string | null
    timebasedmenutiercode: string | null
    paymenttiercode: string | null
    storetierid: string | null
    menustructuretierid: string | null
    timebasedmenutierid: string | null
    paymenttierid: string | null
    posterminalcode: string | null
    vatbuspostinggroup: string | null
    customerno: string | null
    genbuspostinggroup: string | null
    taxidentificationno: string | null
    requestnoofguest: boolean
    useseatingcapacity: boolean
    coverexceedcapacity: boolean
    active: boolean
    enableordertype: IEnableOrderTypeItem[]
    allowpopupcustomization: boolean
    onmodifyorder: string | null
    productionsections?: any
    sectiondispstatmappings?: any
    brandingcustomization: IBrandingAndCustomization | null
    paymentsetups?: any
    receiptsetups?: any
    integrations?: any
    visibleto?: string | null
    approvalmode?: string | null
}

interface IBrandingAndCustomization {
    brandaccentcolor: string
    fonts: {
        heading: {
            typeface: string | null
            color: string
        }
        body: {
            typeface: string | null
            color: string
        }
    }
    logo?: any
}

export interface IEnableOrderTypeItem {
    code: string | null
    name: string | null
    minimumqty: number | null
    minimumamount: number | null
    leadtime: number | null
}

export function SMerchants({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'fnb/merchants'
    const [tableData, setTableData] = useState<ISMerchant[]>([])
    const { data: dataQuery, isSuccess, isFetching, refetch } = apiFnb.useMerchants()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const { data: provincesData, isSuccess: isProvinces } = optionAddress.useProvinces()
    const optionProvinces = isProvinces ? optionAddress.provinces(provincesData) : []

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.store.id' />,
        accessor: 'storecode',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].storecode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.merchant.name' />,
        accessor: 'storename',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].storename} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.address' />,
        accessor: 'address',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].address} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.province' />,
        accessor: 'province',
        Cell: ({ ...props }) => <BasicCell data={optionProvinces.find(f => f.value == props.data[props.row.index].province)?.label || ''} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.public' />,
        accessor: 'merchanturlpublic',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].merchanturlpublic} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<ISMerchant | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: ISMerchant[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.merchanturlpublic == filter.merchanturlpublic,
            ]
            return checks.every(val => val == true)
        })
    }

    const toolbar = <>
        <FilterDropdown
            handleSubmit={handleSubmit}
            onClose={() => {
                setFilter(undefined)
                reset({})
            }}
            onSubmit={(data) => setFilter(data)}>
            <KTFormItem title='text.public' labelWidth="auto">
                <label className="form-check form-switch form-check-custom">
                    <input className="form-check-input" type="checkbox" {...register("merchanturlpublic")} />
                </label>
            </KTFormItem>
        </FilterDropdown>
        <KTButton permission={permission} action="new" />
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}
