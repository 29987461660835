import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IConfigStoreTier } from ".";
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { FormSubmit, InputPrice, InputSelect, KTFormItem, KTSVG, TextFormByUser, yup } from "../../../../theme/partials";
import { InputCheckBox } from "../../../../theme/partials/widgets/form/InputCheckbox";
import { InputTime } from "../../../../theme/partials/widgets/form/InputTime";
import { apiAd } from "../../../modules/api/admin";
import { apiFnb } from "../../../modules/api/fnb";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";

const defaultValues: IConfigStoreTier = {
    code: null,
    name: null,
    description: null,
    active: false,
    ordertypes: [],
    recommendeditems: [],
    featureditems: [],
    isrecommended: false,
    isfeatured: false
}

const tablist = [
    { title: 'General', ico: '/media/icons/tabs/info (1).svg' },
    { title: 'Order Type', ico: '/media/icons/tabs/fast-delivery.svg' },
    { title: 'Order Type Assign', ico: '/media/icons/tabs/box.svg' },
    { title: 'Weekly Timing', ico: '/media/icons/menus/weekly_timing.svg' },
    { title: 'Recommendation', ico: '/media/icons/menus/recommendation.svg' },
]

const optionsCodes = [
    { label: 'TAKEAWAY', value: 'TAKEAWAY' },
    { label: 'DINE-IN', value: 'DINE-IN' },
    { label: 'DELIVERY', value: 'DELIVERY' },
]

export function ConfigStoreTierForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'settings/storetiers'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid, isEditWithPermisstion } = getValueId(id, permission)
    const { data, isSuccess, isFetching } = apiAd.useStoreTiersByID<IConfigStoreTier>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IConfigStoreTier>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            name: yup.string().required(),
            ordertypes: yup.array().of(yup.object().shape({
                code: yup.string().test('unique', (val, ctx) => {
                    if (watch("ordertypes")?.filter(f => f.code == val).length > 1) return ctx.createError()
                    return true
                }).required(),
                name: yup.string().required(),
                minimumqty: yup.number().nullable(true).transform(val => val ? Number(val) : null),
                minimumamount: yup.number().nullable(true).transform(val => val ? Number(val) : null),
                leadtimeminutes: yup.number().nullable(true).transform(val => val ? Number(val) : null),
                assigns: yup.array().of(yup.object().shape({
                    categoryid: yup.string().required(),
                    subcategories: yup.array().of(yup.object().shape({
                        id: yup.string().required(),
                        code: yup.string().required(),
                        name: yup.string().required(),
                    })),
                    items: yup.array().of(yup.string())
                })),
                weeklytimings: yup.array().of(yup.object().shape({
                    starttime: yup.string().test('unique', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    endtime: yup.string().test('unique', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                }))
            })),
        })),
    })
    const [tabListId, setTabListId] = useState('General')

    useEffect(() => {
        if (isSuccess && !isFetching) reset(data)
    }, [isFetching])

    async function handleDeleteLine(index: number, key: any) {
        if (!watch(key)) return popupMessage({ icon: "error" })
        return setValue(key, watch(key).filter((f: any, i: number) => i != index) || [])
    }

    const { data: dataCategories, isSuccess: isCategories } = apiFnb.useCategories()
    const optionCategories = isCategories ? formatOptions(dataCategories, ['id', 'name']) : []

    const { data: dataSubCategories, isSuccess: isSubCategories } = apiFnb.useSubCategories()
    const optioSubCategories = isSubCategories ? formatOptions(dataSubCategories, ['id', 'name']) : []

    const { data: dataHopsItem, isSuccess: isHopsItem } = apiFnb.useHospitalityItems()
    const optionItems = isHopsItem ? formatOptions(dataHopsItem, ['id', 'name']) : []

    const [checkedOrderType, setCheckedOrderType] = useState<any>()
    const indexOrderType = checkedOrderType ? watch('ordertypes').findIndex(f => f.code == checkedOrderType.code) : -1

    useEffect(() => {
        if (tabListId == 'Order Type Assign') {
            if ((!checkedOrderType || indexOrderType == -1) && watch('ordertypes').length > 0) {
                setCheckedOrderType(watch('ordertypes')[0])
            }
        }
    }, [tabListId])

    const listRecommendedIds = watch(`ordertypes`).reduce((tol: string[], item) => [
        ...tol, ...item.assigns.reduce((tol1: string[], item1) => [...tol1, ...item1.items], [])
    ], [])
    const listRecommended = optionItems.filter(f => listRecommendedIds.includes(f.value) && f.isrecommended)
    const listFeatured = optionItems.filter(f => listRecommendedIds.includes(f.value) && f.isfeatured)

    const listview: any[] = (watch("ordertypes").filter(f => f.code && f.name))
    const refSubmit = useRef<HTMLButtonElement | null>(null)
    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} elements={<>
            <TextFormByUser data={watch()} />
            <FormSubmit
                type={isEdit}
                isSubmitting={isSubmitting}
                permission={permission}
                handleSubmit={() => refSubmit.current?.click()}
                handleClose={() => navigate(pathElement)} />
        </>} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IConfigStoreTier) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <button ref={refSubmit} type="submit" className="d-none"></button>
            <div className="card-header bg-secondary rounded-top">
                <h3 className="card-title">
                    {tablist.map(text => <div
                        key={text.title}
                        onClick={() => setTabListId(text.title)}
                        className={clsx('btn p-3 py-1 rounded min-w-125px text-center', {
                            'bg-orange text-white': tabListId == text.title,
                        })}>
                        <KTSVG path={text.ico} className={clsx(`svg-icon-2 d-block m-0`, tabListId == text.title ? 'svg-icon-white' : 'svg-icon-dark')} />
                        <FormattedMessage id={'text.' + text.title.replaceAll(" ", "-").toLowerCase() as any} />
                    </div>)}
                </h3>
            </div>
            <div className="card-body">
                <div className={clsx("row", { 'd-none': tabListId != 'General' })}>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.description' isHeight>
                            <textarea className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} rows={3} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                        <KTFormItem title='text.active'>
                            <InputCheckBox {...register('active')} />
                        </KTFormItem>
                    </div>
                </div>
                <div className={clsx("d-flex flex-column", { 'd-none': tabListId != 'Order Type' })}>
                    {watch('ordertypes').map((line, index) => <div key={index} className={clsx("card border border-secondary mb-4 d-row-hover")}>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <KTFormItem title='text.id'>
                                        <Controller
                                            control={control}
                                            name={`ordertypes.${index}.code`}
                                            render={({ field }) => <InputSelect
                                                options={optionsCodes.filter(f => !watch('ordertypes').map(m => m.code).includes(f.value))}
                                                value={optionsCodes.find(x => x.value == field.value)}
                                                onChange={(e) => field.onChange(e.value)}
                                                className={clsx('w-100', { 'form-error': errors.ordertypes?.length && errors.ordertypes[index]?.code })}
                                            />}
                                        />
                                        {/* <input
                                            {...register(`ordertypes.${index}.code`)}
                                            className={clsx(`form-control form-control-sm`, {
                                                'form-error': errors.ordertypes?.length && errors.ordertypes[index]?.code
                                            })}
                                        /> */}
                                    </KTFormItem>
                                    <KTFormItem title='text.minimum-quantity'>
                                        <input
                                            type='number'
                                            {...register(`ordertypes.${index}.minimumqty`)}
                                            className={clsx(`form-control form-control-sm`, {
                                                'form-error': errors.ordertypes?.length && errors.ordertypes[index]?.minimumqty
                                            })}
                                        />
                                    </KTFormItem>
                                    <KTFormItem title='text.lead-time-(minutes)'>
                                        <input
                                            type='number'
                                            {...register(`ordertypes.${index}.leadtimeminutes`)}
                                            className={clsx(`form-control form-control-sm`, {
                                                'form-error': errors.ordertypes?.length && errors.ordertypes[index]?.leadtimeminutes
                                            })}
                                        />
                                    </KTFormItem>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <KTFormItem title='text.name'>
                                        <input
                                            {...register(`ordertypes.${index}.name`)}
                                            className={clsx(`form-control form-control-sm`, {
                                                'form-error': errors.ordertypes?.length && errors.ordertypes[index]?.name
                                            })}
                                        />
                                    </KTFormItem>
                                    <KTFormItem title='text.minimum-amount'>
                                        <Controller
                                            name={`ordertypes.${index}.minimumamount`}
                                            control={control}
                                            render={({ field: { value, onChange } }) => <InputPrice
                                                value={value}
                                                onChange={onChange}
                                                className={clsx({
                                                    'form-error': errors.ordertypes?.length && errors.ordertypes[index]?.minimumamount
                                                })} />}
                                        />
                                    </KTFormItem>
                                </div>
                            </div>
                        </div>
                        <div className={clsx("d-flex w-100", { 'd-none': isEdit && !isEditWithPermisstion })}>
                            <span className="btn btn-icon btn-sm btn-light-danger ms-auto d-cell-hover" onClick={() => handleDeleteLine(index, 'ordertypes')}>
                                <KTSVG path={'/media/icons/duotune/general/gen027.svg'} className={`svg-icon-4 svg-icon-danger`} />
                            </span>
                        </div>
                    </div>)}
                    <div className={clsx("card card-body border border-secondary", { 'd-none': isEdit && !isEditWithPermisstion })}>
                        <span
                            className={clsx("btn btn-icon btn-sm btn-primary rounded-circle mx-auto")}
                            onClick={() => setValue('ordertypes', [...(watch('ordertypes') || []), {
                                code: null,
                                name: null,
                                minimumqty: null,
                                minimumamount: null,
                                leadtimeminutes: null,
                                assigns: [],
                                weeklytimings: []
                            }])}>
                            <i className="bi bi-plus-lg fs-1"></i>
                        </span>
                    </div>
                </div>
                <div className={clsx("row", { 'd-none': tabListId != 'Order Type Assign' })}>
                    <div className="col-12 col-lg-3">
                        <h3><FormattedMessage id="text.order-type" /></h3>
                        <hr />
                        {listview.map((typeValue, ind: number) => <div
                            key={ind}
                            onClick={() => setCheckedOrderType(typeValue)}
                            className={clsx(
                                "p-3 d-flex align-items-center border border-secondary mb-2 d-row-hover cursor-pointer", {
                                "text-dark": typeValue.code != checkedOrderType?.code,
                                "bg-primary text-white": checkedOrderType && (typeValue.code == checkedOrderType.code)
                            })}>
                            <span>{typeValue.name}</span>
                        </div>)}
                    </div>
                    <div className="col-12 col-lg-9">
                        <h3><FormattedMessage id="text.setting-item" /></h3>
                        <hr />
                        {indexOrderType != -1 && watch('ordertypes')[indexOrderType] && <>
                            {watch('ordertypes')[indexOrderType].assigns.map((item, ind) => <div key={ind} className="card border border-secondary mb-4 d-row-hover">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <KTFormItem title='text.category'>
                                                <Controller
                                                    control={control}
                                                    name={`ordertypes.${indexOrderType}.assigns.${ind}.categoryid`}
                                                    render={({ field: { value, onChange } }) => <InputSelect
                                                        options={optionCategories}
                                                        value={optionCategories.find(f => f.value == value) || null}
                                                        onChange={(e) => {
                                                            setValue(`ordertypes.${indexOrderType}.assigns.${ind}.subcategories`, [])
                                                            setValue(`ordertypes.${indexOrderType}.assigns.${ind}.items`, [])
                                                            if (!e) return onChange(null)
                                                            onChange(e.value)
                                                        }}
                                                    />}
                                                />
                                            </KTFormItem>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-12">
                                            <KTFormItem title='text.sub-category' isHeight labelClassName="mt-3">
                                                <Controller
                                                    control={control}
                                                    name={`ordertypes.${indexOrderType}.assigns.${ind}.subcategories`}
                                                    render={({ field: { value, onChange } }) => <InputSelect
                                                        isMulti
                                                        options={optioSubCategories.filter(f => f.categoryid == watch(`ordertypes.${indexOrderType}.assigns.${ind}.categoryid`))}
                                                        value={optioSubCategories.filter(f => value?.map((v: any) => v.id).includes((f.value))) || null}
                                                        onChange={(e) => {
                                                            if (!e) return onChange(null)
                                                            onChange(e.map((v: any) => ({ id: v.id, code: v.code, name: v.name })))
                                                        }}
                                                    />}
                                                />
                                            </KTFormItem>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-check form-check-sm form-check-custom me-3 w-100px">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={optionItems
                                                        .filter(f => watch(`ordertypes.${indexOrderType}.assigns.${ind}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode))
                                                        .length == watch(`ordertypes.${indexOrderType}.assigns.${ind}.items`)?.length}
                                                    value={''}
                                                    onChange={(ev) => {
                                                        if (optionItems
                                                            .filter(f => watch(`ordertypes.${indexOrderType}.assigns.${ind}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode))
                                                            .length == watch(`ordertypes.${indexOrderType}.assigns.${ind}.items`)?.length) {
                                                            setValue(`ordertypes.${indexOrderType}.assigns.${ind}.items`, [])
                                                            return ev
                                                        }
                                                        setValue(`ordertypes.${indexOrderType}.assigns.${ind}.items`, optionItems
                                                            .filter(f => watch(`ordertypes.${indexOrderType}.assigns.${ind}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode))
                                                            .map(i => i.value))
                                                        return ev
                                                    }} />
                                                <FormattedMessage id="text.select-all" />
                                            </label>
                                            <hr />
                                            <div className="row">
                                                {optionItems
                                                    .filter(f => watch(`ordertypes.${indexOrderType}.assigns.${ind}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode)).length
                                                    ? optionItems
                                                        .filter(f => watch(`ordertypes.${indexOrderType}.assigns.${ind}.subcategories`).map((v: any) => v.code).includes(f.subcategorycode))
                                                        .map(item => <div key={item.value} className="col-12 col-xxl-3 col-lg-4 col-sm-6 ">
                                                            <label className="form-check form-check-sm form-check-custom d-inline-block mb-3 me-3">
                                                                <div className="d-flex align-items-start gap-2">
                                                                    <input className="form-check-input" type="checkbox" value={item.value} {...register(`ordertypes.${indexOrderType}.assigns.${ind}.items`)} />
                                                                    {item.label}
                                                                </div>
                                                            </label>
                                                        </div>)
                                                    : <div className="col-12"><FormattedMessage id="table.not-data" /></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex w-100">
                                    <span className="btn btn-icon btn-sm btn-light-danger ms-auto d-cell-hover" onClick={() => handleDeleteLine(ind, `ordertypes.${indexOrderType}.assigns`)}>
                                        <KTSVG path={'/media/icons/duotune/general/gen027.svg'} className={`svg-icon-4 svg-icon-danger`} />
                                    </span>
                                </div>
                            </div>)}
                            <div className="card card-body border border-secondary">
                                <span
                                    className={clsx("btn btn-icon btn-sm btn-primary rounded-circle mx-auto")}
                                    onClick={() => setValue(`ordertypes.${indexOrderType}.assigns`, [
                                        ...(watch(`ordertypes.${indexOrderType}.assigns`) || []), {
                                            categoryid: null,
                                            subcategories: [],
                                            items: []
                                        }
                                    ])}>
                                    <i className="bi bi-plus-lg fs-1"></i>
                                </span>
                            </div>
                        </>}
                    </div>
                </div>
                <div className={clsx("d-flex flex-column", { 'd-none': tabListId != 'Weekly Timing' })}>
                    <table className="table text-center table-bordered m-0">
                        <thead>
                            <tr className="bg-orange bg-opacity-25 text-uppercase">
                                <th scope="col">
                                    <FormattedMessage id="text.order-type" />
                                </th>
                                <th scope="col">
                                    <FormattedMessage id="text.monday" />
                                </th>
                                <th scope="col">
                                    <FormattedMessage id="text.tuesday" />
                                </th>
                                <th scope="col">
                                    <FormattedMessage id="text.wednesday" />
                                </th>
                                <th scope="col">
                                    <FormattedMessage id="text.thursday" />
                                </th>
                                <th scope="col">
                                    <FormattedMessage id="text.friday" />
                                </th>
                                <th scope="col">
                                    <FormattedMessage id="text.saturday" />
                                </th>
                                <th scope="col">
                                    <FormattedMessage id="text.sunday" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {listview.map((item, ind) => <tr key={ind}>
                                <td className="bg-orange bg-opacity-25 fw-bold">
                                    <div className="d-flex flex-center h-100 p-2">
                                        <span className="mw-125px w-125px text-truncate">{item.name}</span>
                                    </div>
                                </td>
                                {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, index) => {
                                    return <td key={index} className="py-3">
                                        <div className="d-flex flex-column text-lowercase">
                                            <input className="d-none" {...register(`ordertypes.${ind}.weeklytimings.${index}.name`)} value={day} />
                                            <label className="form-check form-switch form-switch-sm form-check-custom mb-2 mx-auto">
                                                <input className="form-check-input" type="checkbox" {...register(`ordertypes.${ind}.weeklytimings.${index}.status`, {
                                                    onChange(event) {
                                                        if (!event.target.checked) {
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.starttime`, null)
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.endtime`, null)
                                                        } else {
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.starttime`, '08:00')
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.endtime`, '21:00')
                                                        }
                                                        return event
                                                    },
                                                })} />
                                            </label>
                                            <div id={`is${ind}/${index}`} className="d-flex flex-center flex-fill wrapper-forcus-times">
                                                <Controller
                                                    name={`ordertypes.${ind}.weeklytimings.${index}.starttime`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => <InputTime
                                                        disabled={!watch(`ordertypes.${ind}.weeklytimings.${index}.status`)}
                                                        className="text-center bg-transparent form-control-sm px-0 border-white min-h-20px"
                                                        value={value}
                                                        onChange={onChange}
                                                        onFocus={(e) => {
                                                            document.getElementById(`is${ind}/${index}`)?.classList.add('type1')
                                                        }}
                                                        onBlur={(e) => {
                                                            document.getElementById(`is${ind}/${index}`)?.classList.remove('type1')
                                                        }}
                                                    />}
                                                />
                                                <div className={clsx({ 'd-none': !watch(`ordertypes.${ind}.weeklytimings.${index}.status`) })}>-</div>
                                                <Controller
                                                    name={`ordertypes.${ind}.weeklytimings.${index}.endtime`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => <InputTime
                                                        disabled={!watch(`ordertypes.${ind}.weeklytimings.${index}.status`)}
                                                        className="text-center bg-transparent form-control-sm px-0 border-white min-h-20px"
                                                        value={value}
                                                        onChange={onChange}
                                                        onFocus={(e) => {
                                                            document.getElementById(`is${ind}/${index}`)?.classList.add('type2')
                                                        }}
                                                        onBlur={(e) => {
                                                            document.getElementById(`is${ind}/${index}`)?.classList.remove('type2')
                                                        }}
                                                    />}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                })}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className={clsx("d-flex flex-column", { 'd-none': tabListId != 'Recommendation' })}>
                    <div className="border border-secondary pb-3 rounded mb-4">
                        <div className="d-flex flex-stack bg-light py-3 px-3">
                            <InputCheckBox {...register('isrecommended')} type='checkbox'>
                                <span className="fs-4"><FormattedMessage id="text.recommended" /></span>
                            </InputCheckBox>
                            <label className="form-check form-check-sm form-check-custom text-uppercase">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={listRecommended.length == watch(`recommendeditems`)?.length}
                                    value={''}
                                    onChange={(ev) => {
                                        if (listRecommended.length == watch(`recommendeditems`)?.length) {
                                            setValue(`recommendeditems`, [])
                                            return ev
                                        }
                                        setValue(`recommendeditems`, listRecommended.map(i => i.value))
                                        return ev
                                    }} />
                                <FormattedMessage id="text.select-all" />
                            </label>
                        </div>
                        <hr className="mt-0" />
                        <div className="row px-3">
                            {listRecommended
                                ? listRecommended.map((item, ind) => <div key={ind} className="col-12 col-xxl-3 col-lg-4 col-sm-6 ">
                                    <label className="form-check form-check-sm form-check-custom d-inline-block mb-3 me-3">
                                        <div className="d-flex align-items-start gap-2">
                                            <input className="form-check-input" type="checkbox" value={item.value} {...register(`recommendeditems`)} />
                                            {item.label}
                                        </div>
                                    </label>
                                </div>)
                                : <div className="col-12"><FormattedMessage id="table.not-data" /></div>
                            }
                        </div>
                    </div>
                    <div className="border border-secondary pb-3 rounded">
                        <div className="d-flex flex-stack bg-light py-3 px-3">
                            <InputCheckBox {...register('isfeatured')} type='checkbox'>
                                <span className="fs-4"><FormattedMessage id="text.featured" /></span>
                            </InputCheckBox>
                            <label className="form-check form-check-sm form-check-custom text-uppercase">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={listFeatured.length == watch(`featureditems`)?.length}
                                    value={''}
                                    onChange={(ev) => {
                                        if (listFeatured.length == watch(`featureditems`)?.length) {
                                            setValue(`featureditems`, [])
                                            return ev
                                        }
                                        setValue(`featureditems`, listFeatured.map(i => i.value))
                                        return ev
                                    }} />
                                <FormattedMessage id="text.select-all" />
                            </label>
                        </div>
                        <hr className="mt-0" />
                        <div className="row px-3">
                            {listFeatured
                                ? listFeatured.map((item, ind) => <div key={ind} className="col-12 col-xxl-3 col-lg-4 col-sm-6 ">
                                    <label className="form-check form-check-sm form-check-custom d-inline-block mb-3 me-3">
                                        <div className="d-flex align-items-start gap-2">
                                            <input className="form-check-input" type="checkbox" value={item.value} {...register(`featureditems`)} />
                                            {item.label}
                                        </div>
                                    </label>
                                </div>)
                                : <div className="col-12"><FormattedMessage id="table.not-data" /></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}